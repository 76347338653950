import Alert from 'react-bootstrap/Alert';

const CustomAlert = ({ show, title, message, handleClose }) => {
  return (
    <Alert variant='warning' show={show} onClose={handleClose} dismissible>
      <Alert.Heading>{title}</Alert.Heading>
      <p>{message}</p>
    </Alert>
  );
};

export default CustomAlert;
