import Button from 'react-bootstrap/Button';
import '../../styles/components/custom-button.css';

const CustomButton = ({
  children,
  url = '',
  onClick = null,
  paddingSize = 'lg',
}) => {
  const btnProps = {};
  if (url !== '') {
    btnProps.href = url;
    btnProps.target = url.startsWith('/#') ? '_self' : '_blank';
  }

  if (onClick !== null) {
    btnProps.onClick = onClick;
  }

  return (
    <div id='custom-button' className={paddingSize}>
      <Button {...btnProps}>{children}</Button>
    </div>
  );
};

export default CustomButton;
