import '../../styles/components/box.css';

const Box = ({ title, icon, onClick = () => {} }) => {
  return (
    <div id='box' onClick={onClick}>
      <div className='icon-container'>
        <img src={icon} alt={title} />
      </div>
      <div className='title-container'>
        <div className='title-container--text'>
          {title} <span className='arrow'>{' >'}</span>
        </div>
      </div>
    </div>
  );
};

export default Box;
