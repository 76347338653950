import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CustomButton from '../CustomButton';
import '../../styles/components/services-modal.css';

const ServicesModal = (props) => {
  const { title, icon, content, onHide } = props;

  const onClick = () => {
    onHide();
  };
  return (
    <Modal
      {...props}
      size='xl'
      aria-labelledby='contained-modal-title-vcenter'
      centered
      className='services'
    >
      <Modal.Header closeButton />
      <Modal.Body>
        <div className='services-modal'>
          <Container fluid>
            <Row>
              <Col xs={12} lg={3} className='modal-icon-column'>
                <div className='modal-icon-container'>
                  <img src={icon} alt={title} className='modal-icon' />
                </div>
                <div className='modal-title-container'>{title}</div>
              </Col>
              <Col xs={12} lg={9}>
                <div className='modal-content-container'>{content}</div>
                <div className='modal-button-container'>
                  <CustomButton onClick={onClick} url='/#contact-us'>
                    CONTACT US
                  </CustomButton>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ServicesModal;
