import { useState } from 'react';
import Home from '../../sections/home';
import Portfolio from '../../sections/portfolio';
import Services from '../../sections/services';
import Partners from '../../sections/partners';
import ContactUs from '../../sections/contact-us';
import Footer from '../../sections/footer';
import CustomAlert from '../CustomAlert';
import '../../styles/layout/layout.css';

const title = 'Development mode';
const message =
  "Thanks for checking out our website! Please keep in mind that we're currently in the process of making some improvements, so you may come across a few bugs or errors as you browse.";

const Layout = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  };

  return (
    <div className='layout-container'>
      {
        <CustomAlert
          show={show}
          title={title}
          message={message}
          handleClose={handleClose}
        />
      }
      <Home />
      <Services />
      <div className='portfolio-bg'>
        <Portfolio />
        <Partners />
      </div>
      <ContactUs />
      <Footer />
    </div>
  );
};

export default Layout;
