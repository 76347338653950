import tokenIcon from '../assets/images/services/token-icon.png';
import walletIcon from '../assets/images/services/wallet-icon.png';
import defiIcon from '../assets/images/services/defi-icon.png';
import nftIcon from '../assets/images/services/nft-icon.png';
import smartContractIcon from '../assets/images/services/smartcontract-icon.png';
import gameIcon from '../assets/images/services/game-icon.png';
import mobileIcon from '../assets/images/services/mobile-icon.png';
import webIcon from '../assets/images/services/web-icon.png';
import mggLogo from '../assets/images/portfolio/mgg.png';
import mswLogo from '../assets/images/portfolio/msw.png';
import sparkplayLogo from '../assets/images/portfolio/sparkplay.png';
import sparkpointWalletLogo from '../assets/images/portfolio/sparkpoint-wallet.png';
import sparkswapLogo from '../assets/images/portfolio/sparkswap.png';
import srkLogo from '../assets/images/portfolio/srk-logo.png';

export const servicesData = {
  'token-dev': {
    serviceTitle: 'Token Development',
    title: 'Token Development',
    icon: tokenIcon,
    content: `SparkTech.Dev offers expert token development services across multiple blockchain platforms like Ethereum and BNB Smart Chain. We cater to your specific token needs, from ERC-20 TO BEP-721 and beyond, by designing and developing secure and custom tokens that align with your business use case. With us, leverage blockchain technology to create bespoke tokens that meet your unique requirements and objectives.`
  },
  'crypto-wallet': {
    serviceTitle: 'Cryptocurrency',
    title: 'Cryptocurrency and E-wallet app',
    icon: walletIcon,
    content: `SparkTech.Dev is one of the pioneering firms for web and mobile crypto wallet development in the Philippines, providing secure and convenient custodial and non-custodial options. Our smart wallets enable users to interact with decentralized apps and smart contracts. We also offer wallet and crypto payments integration services for businesses that want to accept crypto payments or offer customized wallets.`
  },
  'dex-defi': {
    serviceTitle: 'DEX, DeFi Platform and DApps',
    title: 'DEX, DeFi Platform and DApps',
    icon: defiIcon,
    content: `SparkTech.Dev is a leading DeFi development service provider, delivering high-quality solutions for various use cases. Our expertise covers design, development, integration, and maintenace of Decentralized Exchanges (DEX), which enable peer-to-peer trading without intermediaries. We offer secure and fast blockchain-based financial transactions, easy DeFi asset management, and safe and transparent fundraising through Initial DEX Offerings (IDOs). From startups to enterprises, we help you harness DeFi's potential to transform your business and create value for your customers.`,
  },
  'nft-dev': {
    serviceTitle: 'NFT Development',
    title: 'NFT Development, NFT Marketplace',
    icon: nftIcon,
    content: `NFTs are unique digital assets that can represent art, music, game items, and more, providing creators and collectors with new ways to monetize and enjoy their creations. SparkTech.Dev offers NFT Development Services that can help you build and launch your own NFT solutions including marketplaces for buying, selling, and trading NFTs, or platforms for tokenizing game assets for play-to-earn (P2E) games. Whether you need to create new NFTs or integrate them into existing products, SparkTech.Dev's NFT Development Services offer the expertise and tools necessary for success in the thriving NFT industry.`
  },
  'smart-contract-review': {
    serviceTitle: 'Smart Contract Security Review',
    title: 'Smart Contract Security Review',
    icon: smartContractIcon,
    content: `Blockchain applications offer significant opportunities, but also present unique challenges and risks. To ensure the functionality and security of smart contracts, which serve as the backbone of blockchain applications, SparkTech.Dev provides a comprehensive smart contract review service. Our team of experts analyzes smart contracts for vulnerabilities, errors, or inefficiencies and provides a report with feedback and recommendations for enhancements. We offer both one-time reviews and ongoing maintenance plans to assist businesses of all sizes in launching and sustaining their blockchain applications with certainty.`,
  },
  'web3-game-dev': {
    serviceTitle: 'Game Development',
    title: 'Web3 Game Development for 2D and 3D, etc.',
    icon: gameIcon,
    content: `SparkTech.Dev has been developing and launching various blockchain games for years, making us a reliable partner for blockchain game development. Our team consists of experienced and skilled developers who employ advanced strategies to create games that meet the current market trends. Whether you desire a hypercasual, 2D, or 3D game, we can help you build a blockchain gaming platform that delivers an immersive and engaging experience to your users. Additionally, we carefully select the appropriate technology stack to ensure the security, scalability, and performance of your game. Trust SparkTech.Dev for your blockchain game development needs.`,
  },
  'mobile-dev': {
    serviceTitle: 'Mobile App Development for Android and iOS',
    title: 'Mobile App Development for Android and iOS',
    icon: mobileIcon,
    content: `Looking for a reliable and experienced partner to create engaging and user-friendly mobile apps? Look no further than SparkTech.Dev. Our mobile app development services are designed to bring your project to every device and platform. Whether you need an iOS or Android app, or both, we have the skills and expertise to deliver high-quality solutions that meet your needs. Contact our mobile app experts today to get started on your next project!`,
  },
  'web-dev': {
    serviceTitle: 'Web Development',
    title: 'Web Development',
    icon: webIcon,
    content: `With SparkTech.Dev's website development services, you can establish a professional image for your brand and enhance your online presence. Our team of skilled designers works with you to create a website that looks great and functions seamlessly. We cater to your specific requirements from simple to complex websites. Our servicess include concept, design, development, testing and deployment. We also optimize your website for search engines and mobile devices to provide a seamless browsing experience for your customers. Trust SparkTech.Dev for your website design needs and stand out online. `
  }
};

export const portfoliosData = [
  {
    title: "Meta Gaming Guild's GameFi Services and MGG Token",
    icon: mggLogo,
    alt: 'MetaGaming Guild',
    backgroundImgClass: 'partner-mgg',
    servicesAvailed: [
      'token-dev',
      'dex-defi',
      'nft-dev',
      'smart-contract-review',
      'web-dev',
    ],
    description: [
      `MetaGaming Guild is a DAO-based ecosystem that unites Gaming
    Guild, INO/IGO Launchpad, GameFi Vaults, Game Yield Farming,
    and Play-to-Earn Game, with the goal of democratizing game
    finance. MGG leverages NFTs to maximize benefits for its
    community members.`,
    ],
    url: 'https://www.metagg.com/',
  },
  {
    title: 'MetaSaga Warriors',
    icon: mswLogo,
    alt: 'MetaSaga Warriors',
    backgroundImgClass: 'partner-msw',
    servicesAvailed: [
      'nft-dev',
      'smart-contract-review',
      'nft-dev',
      'web3-game-dev',
      'web-dev',
    ],
    description: [
      `MetaSaga Warriors is a roguelike dungeon crawler FREE-TO-OWN NFT game where you command a party of warriors (known as Diggers) on a mission to stop the corruption that encroached upon their paradise. These warriors, their parts, weapons, and other equipment are non-fungible tokens. The game is being developed by MetaGaming Guild (MGG) with SparkTech.Dev as its official development services provider. MetaSaga Warriors will be launched as MGG’s flagship game offer. The game is set to be released in the first quarter of 2023.`,
    ],
    url: 'https://metasagawarriors.com/',
  },
  {
    title: 'SparkPlay Hypercasual Games',
    icon: sparkplayLogo,
    alt: 'SparkPlay Hypercasual Games',
    backgroundImgClass: 'partner-sparkplay',
    servicesAvailed: [
      'mobile-dev',
      'web3-game-dev',
    ],
    description: [
      `SparkPlay is an innovative gaming platform with a collection of crypto-themed mobile hyper casual games. Currently available games include Crypto Slicer, Crypto Burst, Crypto Shooter, and Crypto Loco.`,
    ],
    url: 'https://sparkpoint.io/#/play',
  },
  {
    title: 'SparkPoint Non-Custodial Cryptocurrency Wallet App',
    icon: sparkpointWalletLogo,
    alt: 'SparkPoint Non-Custodial Cryptocurrency Wallet App',
    backgroundImgClass: 'partner-wallet',
    servicesAvailed: [
      'token-dev',
      'dex-defi',
      'nft-dev',
      'smart-contract-review',
      'mobile-dev'
    ],
    description: [
      `SparkPoint Wallet is a non-custodial cryptocurrency wallet that can store SparkPoint (SRK) Token, Bitcoin, Ethereum, Binance Coin, and other ERC-20 and BEP-20 tokens. The wallet also supports tokens based on the Polygon Network. It has in-app exchanges such as ChangeNOW, Changelly, Swapzone, and NFT Gallery where you can store your NFT Collections. SparkPoint Wallet offers a high level of security and privacy. The app is one of SparkPoint’s earliest products and is regularly updated with new features.`,
    ],
    url: 'https://sparkpoint.io/#/wallet',
  },
  {
    title: 'SparkSwap DeFi Platform',
    icon: sparkswapLogo,
    alt: 'SparkSwap DeFi Platform',
    backgroundImgClass: 'partner-sparkswap',
    servicesAvailed: [
      'token-dev',
      'dex-defi',
      'nft-dev',
      'smart-contract-review',
      'web-dev'
    ],
    description: [
      `SparkSwap is a Decentralized Finance (DeFi) platform developed by SparkPoint. It is an all-in-one platform for swapping cryptocurrencies, bridging, earning rewards through staking, launching new projects, and managing your cryptocurrency assets and NFT collections. The SFUEL token is SparkSwap’s governance token that enables SFUEL holders to delegate governance of the SparkSwap platform. These DAO members are responsible for directing the protocol through systematic voting, which is a feature of SFUEL that will be available soon.`,
    ],
    url: 'https://sparkswap.finance',
  },
  {
    title: 'SparkPoint Ecosystem and SRK Token',
    icon: srkLogo,
    alt: 'SparkPoint Ecosystem and SRK Token',
    backgroundImgClass: 'partner-sparkpoint-ecosystem',
    servicesAvailed: [
      'token-dev',
      'dex-defi',
      'nft-dev',
      'smart-contract-review',
      'web-dev',
      'mobile-dev',
      'crypto-wallet',
      'web3-game-dev'
    ],
    description: [
      `SparkPoint Technologies Inc. is a blockchain technology company launched in 2018, and is one of the first cryptocurrency startups in the Philippines. It is currently based in the Albay Province, located in the Bicol Region. SparkPoint aims to accelerate the mainstream adoption of blockchain technology and cryptocurrency through its ecosystem of decentralized apps, DeFi and FinTech platforms, education-technology platform, gaming, and blockchain-based software development services.`,
    ],
    url: 'https://sparkpoint.io',
  },
];

export const technologiesData = [
  'Truffle', 'Solidity', 'Hardhat', 'React-Native',
  'ReactJS', 'Bootstrap', 'ExpressJS', 'PHP',
  'Laravel', 'MySQL', 'MongoDB', 'Rest',
  'GraphQL', 'Vercel'
];


