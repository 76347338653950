import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import AliceCarousel from 'react-alice-carousel';
import CustomButton from '../../CustomButton';
import 'react-alice-carousel/lib/alice-carousel.css';
import '../../../styles/components/hero-carousel.css';
import hero1Img from '../../../assets/images/home/hero.png';
import mggImg from '../../../assets/images/portfolio/mgg.png';
import mswHero from '../../../assets/images/hero/hero-msw-img.png';
import mswLogo from '../../../assets/images/hero/hero-msw-logo.png';
import smartContractHero from '../../../assets/images/hero/hero-smart-contract-img.png';
import heroSparkPointGdap from '../../../assets/images/hero/hero-sparkpoint-gdap.png';
import heroServices from '../../../assets/images/hero/hero-services.png';
import sparkpointGdapImg from '../../../assets/images/hero/sparkpoint-gdap-img.png';
import thumbsSmartContract from '../../../assets/images/hero/thumbs-smartcontract.png';
import thumbsGdap from '../../../assets/images/hero/thumbs-gdap.png';
import thumbsServices from '../../../assets/images/hero/thumbs-services.png';

const items = [
  <div className='item' data-value='1'>
    {/* DESKTOP VIEW */}
    <div className='d-none d-lg-block'>
      <div className='hero-item-container hero-default-background'>
        <Container fluid>
          <Row>
            <Col xxl={8} xl={8} className='p-0 m-0'>
              <div className='hero-header-container'>
                <div className='header-text'>
                  <div className='hero-intro-text'>
                    Onboard the future, make your move with
                  </div>
                  <div className='hero-title-text'>
                    SparkTech<span className='light-blue'>.Dev</span>
                  </div>
                  <div className='hero-subtitle-text'>
                    IDEATE, BUILD, EXECUTE
                  </div>
                </div>
              </div>
            </Col>
            <Col xxl={4} xl={4} className='p-0 m-0'>
              <div className='home-hero-img'>
                <img src={hero1Img} className='hero' alt='Hero' />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
    {/* MOBILE VIEW */}
    <div className='d-block d-lg-none'>
      <div className='hero-item-container hero-default-background'>
        <div className='mobile-hero-img-container'>
          <img src={hero1Img} className='hero' alt='Hero' />
        </div>
        <div className='header-text'>
          <div className='hero-intro-text'>
            Onboard the future, make your move with
          </div>
          <div className='hero-title-text'>
            SparkTech<span className='light-blue'>.Dev</span>
          </div>
          <div className='hero-subtitle-text'>IDEATE, BUILD, EXECUTE</div>
        </div>
      </div>
    </div>
  </div>,
  <div className='item' data-value='2'>
    {/* DESKTOP VIEW */}
    <div className='d-none d-lg-block'>
      <div className='hero-item-container msw-hero-background'>
        <Container fluid>
          <Row>
            <Col xxl={7} xl={7} className='p-0 m-0'>
              <div className='hero-header-container'>
                <img
                  src={mswHero}
                  className='hero-img msw-hero'
                  alt='MetaSaga Warriors'
                />
              </div>
            </Col>
            <Col xxl={5} xl={5} className='p-0 m-0'>
              <div className='hero-description-section'>
                <div className='hero-description'>
                  Checkout the latest developments on MetaGaming Guild and
                  SparkTech<span className='light-blue'>.Dev</span>'s latest
                  collaboration!
                </div>
                <div className='hero-description-img'>
                  <img src={mswLogo} alt='Hero' />
                </div>
                <div className='hero-cta-container'>
                  <CustomButton
                    url='https://metasagawarriors.com'
                    paddingSize='med'
                  >
                    PLAY THE GAME (BETA)
                  </CustomButton>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
    {/* MOBILE VIEW */}
    <div className='d-block d-lg-none'>
      <div className='hero-item-container msw-hero-background'>
        <Container fluid>
          <Row>
            <Col xs={12} className='px-3 pt-2 pb-0 px-md-5 pt-md-5 m-0'>
              <div className='mobile-hero-img-container'>
                <img src={mswHero} className='hero' alt='MetaSaga Warriors' />
              </div>
            </Col>
            <Col xs={12} className='px-4 pt-0 px-md-5 pb-2 m-0'>
              <div className='hero-description-section'>
                <div className='hero-description'>
                  Checkout the latest developments on MetaGaming Guild and
                  SparkTech
                  <span className='light-blue'>.Dev</span>'s latest
                  collaboration!
                </div>
                <Container fluid className='mt-4'>
                  <Row className='pb-md-5'>
                    <Col
                      xs={6}
                      className='p-0 m-0 d-flex justify-content-center'
                    >
                      <div className='hero-description-img'>
                        <img src={mswLogo} alt='Hero' />
                      </div>
                    </Col>
                    <Col
                      xs={6}
                      className='p-0 m-0 d-flex justify-content-center align-items-center'
                    >
                      <div className='hero-cta-container'>
                        <CustomButton
                          url='https://metasagawarriors.com'
                          paddingSize='med'
                        >
                          PLAY THE GAME (BETA)
                        </CustomButton>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  </div>,
  <div className='item' data-value='3'>
    {/* DESKTOP VIEW */}
    <div className='d-none d-lg-block'>
      <div className='hero-item-container hero-default-background'>
        <Container fluid>
          <Row>
            <Col xxl={7} xl={7} className='p-0 m-0'>
              <div className='hero-header-container'>
                <img
                  src={smartContractHero}
                  className='hero-img'
                  alt='Smart Contract Services'
                />
              </div>
            </Col>
            <Col xxl={5} xl={5} className='p-0 m-0'>
              <div className='hero-description-section'>
                <div className='hero-description'>
                  Smart Contracts are the heart of every blockchain and Web3
                  project. Power your next project with secure, accurate and
                  transparent Smart Contracts developed and reviewed by
                  SparkTech<span className='light-blue'>.Dev</span>.
                </div>
                <div className='hero-cta-container'>
                  <CustomButton url='/#contact-us'>
                    GET A QUOTATION
                  </CustomButton>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
    {/* MOBILE VIEW */}
    <div className='d-block d-lg-none'>
      <div className='hero-item-container hero-default-background'>
        <Container fluid>
          <Row>
            <Col xs={12} className='px-3 pt-2 pb-0 px-md-5 pt-md-5 m-0'>
              <div className='mobile-hero-img-container'>
                <img
                  src={smartContractHero}
                  className='hero'
                  alt='Smart Contract Services'
                />
              </div>
            </Col>
            <Col xs={12} className='px-4 pt-0 pb-2 px-md-5 m-0'>
              <div className='hero-description-section'>
                <div className='hero-description'>
                  Smart Contracts are the heart of every blockchain and Web3
                  project. Power your next project with secure, accurate and
                  transparent Smart Contracts developed and reviewed by
                  SparkTech<span className='light-blue'>.Dev</span>.
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12} className='px-4 py-4 px-md-5 pb-md-5 m-0'>
              <div className='hero-cta-container'>
                <CustomButton url='/#contact-us'>GET A QUOTATION</CustomButton>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  </div>,
  <div className='item' data-value='4'>
    {/* DESKTOP VIEW */}
    <div className='d-none d-lg-block'>
      <div className='hero-item-container hero-default-background'>
        <Container fluid>
          <Row>
            <Col xxl={7} xl={7} className='p-0 m-0'>
              <div className='hero-header-container'>
                <img
                  src={heroSparkPointGdap}
                  className='hero-img'
                  alt='SparkPoint GDAP'
                />
              </div>
            </Col>
            <Col xxl={5} xl={5} className='p-0 m-0'>
              <div className='hero-description-section'>
                <div className='sparkpoint-gdap'>
                  <img src={sparkpointGdapImg} alt='SparkPoint GDAP' />
                </div>
                <div className='hero-description'>
                  SparkTech<span className='light-blue'>.Dev</span> is an
                  accredited development services provider through SparkPoint's
                  membership under Game Developers Association of the
                  Philippines. An endless possibility for Web3 gaming is next.
                </div>
                <Container fluid>
                  <div className='hero-cta-container'>
                    <Row>
                      <Col>
                        <CustomButton url='/#contact-us'>
                          GET A QUOTATION
                        </CustomButton>
                      </Col>
                      <Col>
                        <CustomButton url='https://medium.com/p/7e90d447d4f2'>
                          PRESS RELEASE
                        </CustomButton>
                      </Col>
                    </Row>
                  </div>
                </Container>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
    {/* MOBILE VIEW */}
    <div className='d-block d-lg-none'>
      <div className='hero-item-container hero-default-background'>
        <Container fluid>
          <Row>
            <Col xs={12} className='px-4 pt-2 pb-0 px-md-5 pt-md-5 m-0'>
              <div className='mobile-hero-img-container'>
                <img
                  src={heroSparkPointGdap}
                  className='hero'
                  alt='SparkPoint GDAP'
                />
              </div>
              <div className='sparkpoint-gdap'>
                <img src={sparkpointGdapImg} alt='SparkPoint GDAP' />
              </div>
            </Col>
            <Col xs={12} className='px-4 pt-0 pb-2 px-md-5 m-0'>
              <div className='hero-description-section'>
                <div className='hero-description'>
                  SparkTech<span className='light-blue'>.Dev</span> is an
                  accredited development services provider through SparkPoint's
                  membership under Game Developers Association of the
                  Philippines. An endless possibility for Web3 gaming is next.
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12} className='px-4 py-4 px-md-5 pb-md-5 m-0'>
              <Container fluid>
                <div className='hero-cta-container'>
                  <Row>
                    <Col>
                      <CustomButton url='/#contact-us'>
                        GET A QUOTATION
                      </CustomButton>
                    </Col>
                    <Col>
                      <CustomButton url='https://medium.com/p/7e90d447d4f2'>
                        PRESS RELEASE
                      </CustomButton>
                    </Col>
                  </Row>
                </div>
              </Container>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  </div>,
  <div className='item' data-value='5'>
    {/* DESKTOP VIEW */}
    <div className='d-none d-lg-block'>
      <div className='hero-item-container hero-default-background'>
        <Container fluid>
          <Row>
            <Col xxl={7} xl={7} className='p-0 m-0'>
              <div className='hero-header-container'>
                <img src={heroServices} className='hero-img' alt='Services' />
              </div>
            </Col>
            <Col xxl={5} xl={5} className='p-0 m-0'>
              <div className='hero-description-section'>
                <div className='hero-description'>
                  Spare yourself the hassle and let SparkTech
                  <span className='light-blue'>.Dev</span> do everything for
                  you, from frontend to backend. From DeFi platforms, to simple
                  web development, we got your back.
                </div>
                <div className='hero-cta-container'>
                  <CustomButton url='/#more-services'>
                    EXPLORE MORE OF OUR SERVICES
                  </CustomButton>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
    {/* MOBILE VIEW */}
    <div className='d-block d-lg-none'>
      <div className='hero-item-container hero-default-background'>
        <Container fluid>
          <Row>
            <Col xs={12} className='px-3 pt-2 pb-0 px-md-5 pt-md-5 m-0'>
              <div className='mobile-hero-img-container'>
                <img src={heroServices} className='hero' alt='Services' />
              </div>
            </Col>
            <Col xs={12} className='px-4 pt-0 pb-2 px-md-5 m-0'>
              <div className='hero-description-section'>
                <div className='hero-description'>
                  Spare yourself the hassle and let SparkTech
                  <span className='light-blue'>.Dev</span> do everything for
                  you, from frontend to backend. From DeFi platforms, to simple
                  web development, we got your back.
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12} className='px-4 py-4 px-md-5 pb-md-5 m-0'>
              <div className='hero-cta-container'>
                <CustomButton url='/#more-services'>
                  EXPLORE MORE OF OUR SERVICES
                </CustomButton>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  </div>,
];

const thumbnailItems = [
  <div className='item' data-value='1'>
    <div className='thumbnail-container'>
      <img src={hero1Img} className='thumbImage' alt='Hero' />
    </div>
  </div>,
  <div className='item' data-value='2'>
    <div className='thumbnail-container'>
      <img src={mggImg} className='thumbImage' alt='MGG' />
    </div>
  </div>,
  <div className='item' data-value='3'>
    <div className='thumbnail-container'>
      <img
        src={thumbsSmartContract}
        className='thumbImage'
        alt='Smart Contract Services'
      />
    </div>
  </div>,
  <div className='item' data-value='4'>
    <div className='thumbnail-container'>
      <img src={thumbsGdap} className='thumbImage' alt='GDAP' />
    </div>
  </div>,
  <div className='item' data-value='5'>
    <div className='thumbnail-container'>
      <img src={thumbsServices} className='thumbImage' alt='Services' />
    </div>
  </div>,
];

const thumbItems = (items, [setThumbIndex, setThumbAnimation]) => {
  return items.map((item, i) => (
    <div
      key={i}
      className='thumb'
      // eslint-disable-next-line no-sequences
      onClick={() => (setThumbIndex(i), setThumbAnimation(true))}
    >
      {item}
    </div>
  ));
};

const HeroCarousel = () => {
  const [mainIndex, setMainIndex] = useState(0);
  const [mainAnimation, setMainAnimation] = useState(false);
  const [thumbIndex, setThumbIndex] = useState(0);
  const [thumbAnimation, setThumbAnimation] = useState(false);
  const [thumbs] = useState(
    thumbItems(thumbnailItems, [setThumbIndex, setThumbAnimation])
  );

  const slideNext = () => {
    if (!thumbAnimation && thumbIndex < thumbs.length - 1) {
      setThumbAnimation(true);
      setThumbIndex(thumbIndex + 1);
    } else {
      setThumbIndex(0);
    }
  };

  const slidePrev = () => {
    if (!thumbAnimation && thumbIndex > 0) {
      setThumbAnimation(true);
      setThumbIndex(thumbIndex - 1);
    } else {
      setThumbIndex(thumbs.length - 1);
    }
  };

  const syncMainBeforeChange = (e) => {
    setMainAnimation(true);
  };

  const syncMainAfterChange = (e) => {
    setMainAnimation(false);

    if (e.type === 'action') {
      setThumbIndex(e.item);
      setThumbAnimation(false);
    } else {
      setMainIndex(thumbIndex);
    }
  };

  const syncThumbs = (e) => {
    setThumbIndex(e.item);
    setThumbAnimation(false);

    if (!mainAnimation) {
      setMainIndex(e.item);
    }
  };

  return [
    <AliceCarousel
      activeIndex={mainIndex}
      animationType='fadeout'
      animationDuration={800}
      disableDotsControls
      disableButtonsControls
      items={items}
      infinite
      mouseTracking={!thumbAnimation}
      onSlideChange={syncMainBeforeChange}
      onSlideChanged={syncMainAfterChange}
      touchTracking={!thumbAnimation}
    />,
    <div className='thumbs'>
      <AliceCarousel
        activeIndex={thumbIndex}
        autoWidth
        infinite
        disableDotsControls
        disableButtonsControls
        items={thumbs}
        mouseTracking={false}
        onSlideChanged={syncThumbs}
        touchTracking={!mainAnimation}
      />
      <div className='btn-prev' onClick={slidePrev}>
        &lang;
      </div>
      <div className='btn-next' onClick={slideNext}>
        &rang;
      </div>
    </div>,
  ];
};

export default HeroCarousel;
