import { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import CustomButton from '../../CustomButton';
import ServicesModal from '../../ServicesModal';
import { servicesData, portfoliosData } from '../../../data';
import '../../../styles/components/portfolio-carousel.css';

const Services = ({ id, handleClick }) => {
  const service = servicesData[id];
  return (
    <div
      className='services-availed'
      onClick={handleClick(service.title, service.content, service.icon)}
    >
      {service.serviceTitle}
    </div>
  );
};

const PortfolioCarousel = () => {
  const [modalShow, setModalShow] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalContent, setModalContent] = useState('');
  const [modalIcon, setModalIcon] = useState(null);

  const openModal = (title, content, icon) => () => {
    setModalTitle(title);
    setModalContent(content);
    setModalIcon(icon);
    setModalShow(true);
  };

  return (
    <>
      <ServicesModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        title={modalTitle}
        content={modalContent}
        icon={modalIcon}
      />
      <Carousel indicators={false} interval={null}>
        {portfoliosData.map((portfolio) => {
          return (
            <Carousel.Item key={portfolio.title}>
              <div className='portfolio-slider-container'>
                <div
                  className={`slider-content ${portfolio.backgroundImgClass}`}
                >
                  <div className='slider-top'>&nbsp;</div>
                  <div className='slider-icon-container'>
                    <div className='slider-icon'>
                      <img src={portfolio.icon} alt={portfolio.alt} />
                    </div>
                  </div>
                </div>
                <div className='slider-details-container'>
                  <div className='slider-details-text'>
                    <div className='slider-title'>{portfolio.title}</div>
                    <div className='services-availed-container'>
                      <div className='services-label'>Services Applied:</div>{' '}
                      {portfolio.servicesAvailed.map((service, index) => {
                        return (
                          <Services
                            key={index}
                            id={service}
                            handleClick={openModal}
                          />
                        );
                      })}
                    </div>
                    <div className='services-description'>
                      {portfolio.description.map((line, index) => {
                        return <p key={index}>{line}</p>;
                      })}
                    </div>
                    <div className='project-link-container'>
                      <CustomButton url={portfolio.url}>
                        Visit Project
                      </CustomButton>
                    </div>
                  </div>
                </div>
              </div>
            </Carousel.Item>
          );
        })}
      </Carousel>
    </>
  );
};

export default PortfolioCarousel;
