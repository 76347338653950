import { useState, useEffect } from 'react';
import Lottie from 'lottie-react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CustomButton from '../../components/CustomButton';
import Box from '../../components/Box';
import ServicesModal from '../../components/ServicesModal';
import isometricAnimation from '../../assets/animation/isometric.json';
import smartContractAnimation from '../../assets/animation/smartcontract2.json';
import frontendBackendAnimation from '../../assets/animation/frontendBackend.json';
import web3GameAnimation from '../../assets/animation/web3game.json';
import truffleIcon from '../../assets/images/technologies/truffle.png';
import solidityIcon from '../../assets/images/technologies/solidity.png';
import bootstrapIcon from '../../assets/images/technologies/bootstrap.png';
import expressjsIcon from '../../assets/images/technologies/expressjs.png';
import graphqlIcon from '../../assets/images/technologies/graphql.png';
import hardhatIcon from '../../assets/images/technologies/hardhat.png';
import laravelIcon from '../../assets/images/technologies/laravel.png';
import mongoIcon from '../../assets/images/technologies/mongo.png';
import mysqlIcon from '../../assets/images/technologies/mysql.png';
import phpIcon from '../../assets/images/technologies/php.png';
import reactjsIcon from '../../assets/images/technologies/reactjs.png';
import restIcon from '../../assets/images/technologies/rest.png';
import vercelIcon from '../../assets/images/technologies/vercel.png';
import { servicesData, technologiesData } from '../../data';
import '../../styles/sections/services.css';

const getWindowDimensions = () => {
  const { outerWidth, innerWidth } = window;
  return { innerWidth, outerWidth };
};

const services = Object.keys(servicesData).map((item) => {
  return servicesData[item];
});

const getFeaturedTech = (index) => {
  return technologiesData[index];
};

const ServicesContent = ({ openModal }) => {
  const items1 = services.slice(0, 4);
  const items2 = services.slice(4);

  const row1 = items1.map((item, index) => {
    return (
      <Col
        key={index}
        xs={6}
        lg={3}
        className='mx-0 mt-0 mb-5 mb-md-5 mb-lg-0 p-0 d-flex justify-content-center'
      >
        <Box
          title={item.title}
          icon={item.icon}
          onClick={openModal(item.title, item.content, item.icon)}
        />
      </Col>
    );
  });

  const row2 = items2.map((item, index) => {
    return (
      <Col
        key={index}
        xs={6}
        lg={3}
        className='mx-0 mt-0 mb-5 mb-md-5 mb-lg-0 p-0 d-flex justify-content-center'
      >
        <Box
          title={item.title}
          icon={item.icon}
          onClick={openModal(item.title, item.content, item.icon)}
        />
      </Col>
    );
  });

  return (
    <div className='items-container'>
      <Container fluid className='m-0 p-0'>
        <Row className='mx-0 mt-0 mb-lg-5 p-0'>{row1}</Row>
        <Row className='mx-0 mt-0 mb-lg-5 p-0'>{row2}</Row>
      </Container>
    </div>
  );
};

const BlockchainTech = () => {
  return (
    <div className='languages-container'>
      <div className='languages'>
        <div className='icon-container'>
          <img src={truffleIcon} alt='Truffle' className='language-icon' />
        </div>
        <div className='language'>Truffle</div>
      </div>
      <div className='languages'>
        <div className='icon-container'>
          <img src={solidityIcon} alt='Solidity' className='language-icon' />
        </div>
        <div className='language'>Solidity</div>
      </div>
      <div className='languages'>
        <div className='icon-container'>
          <img src={hardhatIcon} alt='Hardhat' className='language-icon' />
        </div>
        <div className='language'>Hardhat</div>
      </div>
    </div>
  );
};

const MobileTech = () => {
  return (
    <div className='languages-container'>
      <div className='languages'>
        <div className='icon-container'>
          <img src={reactjsIcon} alt='React-Native' className='language-icon' />
        </div>
        <div className='language'>React-Native</div>
      </div>
    </div>
  );
};

const FrontendTech = () => {
  return (
    <div className='languages-container'>
      <div className='languages'>
        <div className='icon-container'>
          <img src={reactjsIcon} alt='React' className='language-icon' />
        </div>
        <div className='language'>ReactJS</div>
      </div>
      <div className='languages'>
        <div className='icon-container'>
          <img src={bootstrapIcon} alt='Bootstrap' className='language-icon' />
        </div>
        <div className='language'>Bootstrap</div>
      </div>
    </div>
  );
};

const BackendTech = () => {
  return (
    <div className='languages-container'>
      <div className='languages'>
        <div className='icon-container'>
          <img src={expressjsIcon} alt='ExpressJS' className='language-icon' />
        </div>
        <div className='language'>ExpressJS</div>
      </div>
      <div className='languages'>
        <div className='icon-container'>
          <img src={phpIcon} alt='PHP' className='language-icon' />
        </div>
        <div className='language'>PHP</div>
      </div>
      <div className='languages'>
        <div className='icon-container'>
          <img src={laravelIcon} alt='Laravel' className='language-icon' />
        </div>
        <div className='language'>Laravel</div>
      </div>
    </div>
  );
};

const DatabaseTech = () => {
  return (
    <div className='languages-container'>
      <div className='languages'>
        <div className='icon-container'>
          <img src={mysqlIcon} alt='MySQL' className='language-icon' />
        </div>
        <div className='language'>MySQL</div>
      </div>
      <div className='languages'>
        <div className='icon-container'>
          <img src={mongoIcon} alt='MongoDB' className='language-icon' />
        </div>
        <div className='language'>MongoDB</div>
      </div>
    </div>
  );
};

const ServerTech = () => {
  return (
    <div className='languages-container'>
      <div className='languages'>
        <div className='icon-container'>
          <img src={restIcon} alt='Rest' className='language-icon' />
        </div>
        <div className='language'>Rest</div>
      </div>
      <div className='languages'>
        <div className='icon-container'>
          <img src={graphqlIcon} alt='GraphQL' className='language-icon' />
        </div>
        <div className='language'>GraphQL</div>
      </div>
      <div className='languages'>
        <div className='icon-container'>
          <img src={vercelIcon} alt='Vercel' className='language-icon' />
        </div>
        <div className='language'>Vercel</div>
      </div>
    </div>
  );
};

const Technologies = () => {
  return (
    <Tabs
      defaultActiveKey='blockchain'
      id='technologies-tab'
      className='mb-3 mt-5 mt-md-0'
      fill
    >
      <Tab eventKey='blockchain' title='Blockchain'>
        <BlockchainTech />
      </Tab>
      <Tab eventKey='mobile' title='Mobile'>
        <MobileTech />
      </Tab>
      <Tab eventKey='frontend' title='Frontend'>
        <FrontendTech />
      </Tab>
      <Tab eventKey='backend' title='Backend'>
        <BackendTech />
      </Tab>
      <Tab eventKey='database' title='Database'>
        <DatabaseTech />
      </Tab>
      <Tab eventKey='servers' title='Servers'>
        <ServerTech />
      </Tab>
    </Tabs>
  );
};

const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions(getWindowDimensions());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
};

const getAnimationDimension = (innerWidth, outerWidth, type = 'blockchain') => {
  let dimension = 300;
  if (outerWidth < 1200) {
    if (outerWidth >= 768 && outerWidth < 1200) {
      if (type === 'blockchain') {
        dimension = 1200;
      } else if (type === 'web3') {
        dimension = 500;
      } else if (type === 'smartcontract') {
        dimension = 500;
      } else if (type === 'frontend') {
        dimension = 500;
      }
    } else if (outerWidth >= 480 && outerWidth < 768) {
      if (type === 'blockchain') {
        dimension = 700;
      } else if (type === 'web3') {
        dimension = 600;
      } else if (type === 'smartcontract') {
        dimension = 200;
      } else if (type === 'frontend') {
        dimension = 400;
      }
    } else if (outerWidth >= 320 && outerWidth < 480) {
      if (type === 'blockchain') {
        dimension = 550;
      } else if (type === 'web3') {
        dimension = 300;
      } else if (type === 'smartcontract') {
        dimension = 200;
      } else if (type === 'frontend') {
        dimension = 300;
      }
    }
  } else if (outerWidth > 1200) {
    if (innerWidth >= 1280 && innerWidth < 1400) {
      if (type === 'blockchain') {
        dimension = 1200;
      } else if (type === 'web3') {
        dimension = 500;
      } else if (type === 'smartcontract') {
        dimension = 500;
      } else if (type === 'frontend') {
        dimension = 500;
      }
    } else if (innerWidth >= 1400 && innerWidth < 1600) {
      if (type === 'blockchain') {
        dimension = 1200;
      } else if (type === 'web3') {
        dimension = 600;
      } else if (type === 'smartcontract') {
        dimension = 600;
      } else if (type === 'frontend') {
        dimension = 600;
      }
    } else if (innerWidth >= 1600 && innerWidth < 1920) {
      if (type === 'blockchain') {
        dimension = 1500;
      } else if (type === 'web3') {
        dimension = 600;
      } else if (type === 'smartcontract') {
        dimension = 600;
      } else if (type === 'frontend') {
        dimension = 600;
      }
    } else if (innerWidth >= 1920 && innerWidth < 2560) {
      if (type === 'blockchain') {
        dimension = 1900;
      } else if (type === 'web3') {
        dimension = 600;
      } else if (type === 'smartcontract') {
        dimension = 600;
      } else if (type === 'frontend') {
        dimension = 600;
      }
    } else if (innerWidth >= 2560) {
      if (type === 'blockchain') {
        dimension = 2600;
      } else if (type === 'web3') {
        dimension = 1200;
      } else if (type === 'smartcontract') {
        dimension = 1200;
      } else if (type === 'frontend') {
        dimension = 1200;
      }
    }
  }

  return dimension;
};

const Services = () => {
  const [modalShow, setModalShow] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalContent, setModalContent] = useState('');
  const [modalIcon, setModalIcon] = useState(null);
  const [arrayIndex, setArrayIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setArrayIndex((prevIndex) => (prevIndex + 1) % technologiesData.length);
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const openModal = (title, content, icon) => () => {
    setModalTitle(title);
    setModalContent(content);
    setModalIcon(icon);
    setModalShow(true);
  };

  const { innerWidth, outerWidth } = useWindowDimensions();

  return (
    <>
      <ServicesModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        title={modalTitle}
        content={modalContent}
        icon={modalIcon}
      />
      <section id='services'>
        <div className='blockchain-container'>
          <div className='blockchain-text-container'>
            <div className='blockchain-line-1'>We are a team that puts</div>
            <div className='blockchain-line-2'>Blockchain</div>
            <div className='blockchain-line-3'>on the front line</div>
            <div className='blockchain-text-description'>
              <div className='text-background'>
                We believe that blockchain has the potential to transform
                industries create new opportunities for growth. Our cutting-edge
                solutions leverage the advantages of blockchain - security,
                transparency, and efficiency. Our team of blockchain experts,
                developers, designers, and consultants are passionate about
                delivering high-quality products and services to our clients.
              </div>
            </div>
          </div>
          <div className='blockchain-animation-container'>
            <Lottie
              animationData={isometricAnimation}
              loop={true}
              style={{
                width: getAnimationDimension(
                  innerWidth,
                  outerWidth,
                  'blockchain'
                ),
              }}
              className='blockchain-animation'
            />
          </div>
        </div>
        <div className='gamedev-container'>
          <div className='gamedev-content'>
            <div className='gamedev-image-container'>
              <Lottie
                animationData={web3GameAnimation}
                loop={true}
                style={{
                  height: getAnimationDimension(innerWidth, outerWidth, 'web3'),
                }}
                className='smart-contract-animation'
              />
            </div>
            <div className='text-container'>
              <div className='title'>Web3 Game Development</div>
              <div className='description right-padding'>
                From game design to post-release support, our highly productive
                developers will be involved in every phase. We have an extensive
                experience on creating diverse range of games including
                play-to-earn (P2E), play-to-own, crypto+blockchain, NFT games,
                etc., to 3D, 2D, and casual games with some of the brilliant
                game studios and artists in the country.
              </div>
              <div className='web3-button'>
                <CustomButton url='/#contact-us'>
                  CREATE YOUR WEB3 GAME
                </CustomButton>
              </div>
            </div>
          </div>
        </div>
        <div className='services-background'>
          <div className='smart-contract-container'>
            <div className='d-none d-lg-block'>
              <div className='smart-contract-content'>
                <div className='text-container'>
                  <div className='title left-padding'>
                    Smart Contract Development and Deployment
                  </div>
                  <div className='description left-padding'>
                    Automate transactions within your systems with SECURE,
                    ACCURATE, and TRANSPARENT Smart Contracts from SparkPoint.
                    Smart Contracts for Token Project, DeFi Ecosystem and apps,
                    NFT Minting, Web3 apps, etc., we got the right contracts for
                    all of your needs.
                  </div>
                  <div className='web3-button'>
                    <CustomButton url='/#contact-us'>
                      ASK ABOUT SMART CONTRACTS
                    </CustomButton>
                  </div>
                </div>
                <div className='smart-contract-image-container'>
                  <div className='smart-contract-bg'></div>
                  <Lottie
                    animationData={smartContractAnimation}
                    loop={true}
                    style={{
                      height: getAnimationDimension(
                        innerWidth,
                        outerWidth,
                        'smartcontract'
                      ),
                    }}
                    className='smart-contract-animation'
                  />
                </div>
              </div>
            </div>
            <div className='d-block d-lg-none'>
              <div className='smart-contract-image-container'>
                <div className='smart-contract-bg'></div>
                <Lottie
                  animationData={smartContractAnimation}
                  loop={true}
                  style={{
                    height: getAnimationDimension(
                      innerWidth,
                      outerWidth,
                      'smartcontract'
                    ),
                  }}
                  className='smart-contract-animation'
                />
              </div>
              <div className='text-container'>
                <div className='title'>
                  Smart Contract Development and Deployment
                </div>
                <div className='description right-padding'>
                  Automate transactions within your systems with SECURE,
                  ACCURATE, and TRANSPARENT Smart Contracts from SparkPoint.
                  Smart Contracts for Token Project, DeFi Ecosystem and apps,
                  NFT Minting, Web3 apps, etc., we got the right contracts for
                  all of your needs.
                </div>
                <div className='web3-button'>
                  <CustomButton url='/#contact-us'>
                    ASK ABOUT SMART CONTRACTS
                  </CustomButton>
                </div>
              </div>
            </div>
          </div>
          <div className='front-back-container'>
            <div className='d-none d-lg-block'>
              <div className='front-back-content'>
                <div className='front-back-image-container'>
                  <div className='front-back-placeholder'></div>
                  <Lottie
                    animationData={frontendBackendAnimation}
                    loop={true}
                    style={{
                      height: getAnimationDimension(
                        innerWidth,
                        outerWidth,
                        'frontend'
                      ),
                    }}
                    className='frontend-backend-animation'
                  />
                </div>
                <div className='text-container'>
                  <div className='title'>Frontend + Backend Development</div>
                  <div className='description right-padding'>
                    Frontend and backend development skills honed to become
                    expert builders. A key aspect of SparkPoint's successful
                    launch of various dapps and platforms over the years. If you
                    plan on having your own, we have the team to help you
                    succeed in putting up everything up and online.
                  </div>
                  <div className='web3-button'>
                    <CustomButton url='/#contact-us'>
                      MORE ABOUT THIS
                    </CustomButton>
                  </div>
                </div>
              </div>
            </div>
            <div className='d-block d-lg-none'>
              <div className='front-back-image-container'>
                <div className='front-back-placeholder'></div>
                <Lottie
                  animationData={frontendBackendAnimation}
                  loop={true}
                  style={{
                    height: getAnimationDimension(
                      innerWidth,
                      outerWidth,
                      'frontend'
                    ),
                  }}
                  className='frontend-backend-animation'
                />
              </div>
              <div className='text-container'>
                <div className='title'>Frontend + Backend Development</div>
                <div className='description right-padding'>
                  Frontend and backend development skills honed to become expert
                  builders. A key aspect of SparkPoint's successful launch of
                  various dapps and platforms over the years. If you plan on
                  having your own, we have the team to help you succeed in
                  putting up everything up and online.
                </div>
                <div className='web3-button'>
                  <CustomButton url='/#contact-us'>
                    MORE ABOUT THIS
                  </CustomButton>
                </div>
              </div>
            </div>
          </div>
          <div className='tech-container'>
            <div id='more-services' className='tech-header'>
              Explore more of what SparkTech can offer:
            </div>
            <ServicesContent openModal={openModal} />
          </div>
        </div>
        <div className='expertise-container'>
          <div className='expertise-text-header'>
            We speak...
            <br />
            <span className='expertise-emphasis'>
              {getFeaturedTech(arrayIndex)}
            </span>
            <br />
            and many more...
          </div>
          <div className='expertise-description'>
            SparkTech is nothing without the core team that does the hard work.
            At SparkTech, we recognize the importance of keeping pace with the
            ever-evolving technological landscape. Our Dev Team is equipped with
            the latest tech and tools in web programming, mobile application
            development, building and database management, working diligently to
            maintain their proficiency in cutting-edge tools and technologies to
            be able to deliver tasks on time and with the highest level of
            efficiency.
          </div>
          <div className='expertise-tabs-container'>
            <Technologies />
          </div>
        </div>
      </section>
    </>
  );
};

export default Services;
