import Nav from 'react-bootstrap/Nav';
import CustomButton from '../CustomButton';
import navbarImg from '../../assets/images/navigation/navbar-img.png';
import '../../styles/components/navigation.css';

const Navigation = () => {
  return (
    <div className='navigation-container'>
      <div className='d-none d-lg-block'>
        <div id='custom-nav'>
          <div className='nav-container'>
            <div className='nav-header'>
              <img className='header-img' src={navbarImg} alt='SparkTech.Dev' />
            </div>
            <div className='navigation'>
              <Nav className='justify-content-end' activeKey='/home'>
                <Nav.Item>
                  <Nav.Link href='/#home'>HOME</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link href='/#services'>SERVICES</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link href='/#portfolio'>PORTFOLIO</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link href='/#about'>ABOUT US</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link href='/#contact-us'>CONTACT US</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <CustomButton url='/#contact-us' paddingSize='sm'>
                    INQUIRE NOW
                  </CustomButton>
                </Nav.Item>
              </Nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navigation;
