import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import footerLogo from '../../assets/images/footer/footer-logo.png';
import emailIcon from '../../assets/images/footer/email.png';
import twitterIcon from '../../assets/images/footer/twitter.png';
import telegramIcon from '../../assets/images/footer/telegram.png';
import instagramIcon from '../../assets/images/footer/instagram.png';
import facebookIcon from '../../assets/images/footer/facebook.png';

import '../../styles/layout/footer.css';

const Footer = () => {
  return (
    <div id='footer'>
      <div className='footer-container'>
        <Container fluid className='p-0 m-0'>
          <Row className='p-0 m-0'>
            <Col className='p-0 m-0'>
              <div className='footer-title-container'>
                <div className='footer-icon-container'>
                  <img src={footerLogo} className='footer-icon' alt='Footer' />
                </div>
                <div className='footer-title'>
                  SparkTech<span className='blue-emphasis'>.Dev</span>
                </div>
              </div>
              <div className='footer-address'>
                <span className='office-label'>SparkTech Dev Office</span>
                <br />
                2nd Floor, Doña Maria Building,
                <br />
                Lakandula Drive, Tagas <br />
                Daraga, Albay 4501
              </div>
              <div className='footer-contact'>
                <div className='icon-placeholder'>
                  <img src={emailIcon} alt='Email' />
                </div>
                <a href='mailto: hello@sparktech.dev'>hello@sparktech.dev</a>
              </div>
              <div className='footer-contact d-none'>
                <div className='icon-placeholder'></div>
                <a href='tel:+639xxxxxxx'>+63 9XX XXX XXXX</a>
              </div>
            </Col>
            <Col className='p-0 m-0 d-flex justify-content-between flex-column'>
              <div className='footer-soc-med-container'>
                <div className='footer-contact'>
                  <div className='icon-placeholder'>
                    <a
                      href='https://twitter.com/sparktech_dev'
                      target='_blank'
                      rel='noreferrer'
                    >
                      <img src={twitterIcon} alt='Twitter' />
                    </a>
                  </div>
                  <div className='icon-placeholder'>
                    <a
                      href='https://www.facebook.com/sparktechdev'
                      target='_blank'
                      rel='noreferrer'
                    >
                      <img src={facebookIcon} alt='Facebook' />
                    </a>
                  </div>
                  <div className='icon-placeholder'>
                    <a
                      href='https://www.instagram.com/sparktech.dev'
                      target='_blank'
                      rel='noreferrer'
                    >
                      <img src={instagramIcon} alt='Instagram' />
                    </a>
                  </div>
                  <div className='icon-placeholder d-none'>
                    <img src={telegramIcon} alt='Telegram' />
                  </div>
                  @sparktechdev
                </div>
              </div>
              <div className='footer-navigation'>
                <Container className='p-0 m-0' fluid>
                  <Row className='px-0 py-lg-2  mx-0 mt-lg-5'>
                    <Col className='p-0 m-0'>
                      <a href='/#home'>Home</a>
                    </Col>
                    <Col className='p-0 m-0'>
                      <a href='/#about'>About</a>
                    </Col>
                  </Row>
                  <Row className='px-0 py-lg-2 m-0'>
                    <Col className='p-0 m-0'>
                      <a href='/#services'>Services</a>
                    </Col>
                    <Col className='p-0 m-0'>
                      <a href='/#contact-us'>Contact Us</a>
                    </Col>
                  </Row>
                  <Row className='px-0 py-lg-2 m-0'>
                    <Col className='p-0 m-0'>
                      <a href='/#portfolio'>Portfolio</a>
                    </Col>
                    <Col className='p-0 m-0'>
                      <a
                        href='https://sparkpoint.io/#/faqs'
                        target='_blank'
                        rel='noreferrer'
                      >
                        FAQs
                      </a>
                    </Col>
                  </Row>
                  <Row className='px-0 py-lg-2 m-0'>
                    <Col className='p-0 m-0'>
                      <a
                        href='https://sparkpoint.io/#/privacy'
                        target='_blank'
                        rel='noreferrer'
                      >
                        Privacy Policy
                      </a>
                    </Col>
                  </Row>
                </Container>
              </div>
              <div className='footer-copyright-text'>
                @ Copyright 2023 SparkTech Development Services
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Footer;
