import PartnersCarousel from '../../components/Carousel/PartnersCarousel';
import '../../styles/sections/partners.css';

const Partners = () => {
  return (
    <section id='partners'>
      <div className='partners-container'>
        <div className='partners-text-container'>
          <h1 className='partners-title'>Our Partners and Affiliates</h1>
          <p className='partners-subtitle'>
            From collaborations, partnerships to clients
          </p>
        </div>
        <div className='partners-slider-container'>
          <PartnersCarousel />
        </div>
      </div>
    </section>
  );
};

export default Partners;
