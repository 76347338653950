import PortfolioCarousel from '../../components/Carousel/PortfolioCarousel';
import '../../styles/sections/portfolio.css';

const Portfolio = () => {
  return (
    <section id='portfolio'>
      <div className='portfolio-container'>
        <div className='portfolio-background'>
          <div className='portfolio-text-container'>
            <h1 className='portfolio-title'>Our Portfolio</h1>
            <p>
              Through the team's culmination of hard work, talent, and expertise
              in the field of technology, the team has developed and
              successfully launched projects that have been enjoyed by hundreds
              of thousands of users globally, ranging from Web2 to Web3
              projects. Our portfolio is a testament to SparkTech’s
              capabilities, commitment to innovation, and is always game-on, no
              matter what.
            </p>
          </div>
          <div className='portfolio-slider-container'>
            <PortfolioCarousel />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Portfolio;
