import { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import createAxiosInstance from '../../api/axiosInstance';
import '../../styles/sections/contact-us.css';

const retrieveCountryRegionApi = createAxiosInstance(
  'https://www.ibm.com/account/urxstatic/locales'
);
const sendEmailApi = createAxiosInstance(
  'https://test-api-cyan.vercel.app/api/v1/sparktech/contact'
);

const ContactUs = () => {
  const INITIAL_STATE = {
    firstName: '',
    lastName: '',
    country: '',
    region: '',
    phone: '',
    email: '',
    company: '',
    message: '',
  };

  const [countries, setCountries] = useState([]);
  const [regions, setRegions] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);
  const [validated, setValidated] = useState(false);
  const [formState, setFormState] = useState(INITIAL_STATE);
  const [errors, setErrors] = useState(INITIAL_STATE);
  const [modalMessage, setModalMessage] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const [isEmailSending, setIsEmailSending] = useState(false);

  useEffect(() => {
    retrieveCountryRegionApi
      .get('/countries/en.json')
      .then((response) => {
        const data = response.data;
        setCountries(
          Object.keys(data).map((key) => ({
            code: key,
            name: data[key],
          }))
        );
      })
      .catch((error) => console.error(error));
  }, []);

  const validateForm = () => {
    let valid = true;
    const newErrors = Object.assign({}, INITIAL_STATE);

    if (!formState.firstName) {
      newErrors.firstName = 'First name is required';
      valid = false;
    }

    if (!formState.lastName) {
      newErrors.lastName = 'Last name is required';
      valid = false;
    }

    if (!formState.country) {
      newErrors.country = 'Country is required';
      valid = false;
    }

    if (!formState.region) {
      newErrors.region = 'Region is required';
      valid = false;
    }

    if (!formState.email) {
      newErrors.email = 'Email is required';
      valid = false;
    } else if (!/\S+@\S+\.\S+/.test(formState.email)) {
      newErrors.email = 'Email is invalid';
      valid = false;
    }

    if (!formState.phone) {
      newErrors.phone = 'Phone number is required';
      valid = false;
    }

    if (!formState.company) {
      newErrors.company = 'Company is required';
      valid = false;
    }

    if (!formState.message) {
      newErrors.message = 'Message is required';
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setValidated(true);
    if (validateForm()) {
      sendEmail();
    }
  };

  const sendEmail = async () => {
    setModalTitle('Email');
    setIsEmailSending(true);
    handleShowModal();
    const emailParams = Object.assign({}, formState);
    const selectedCountry = countries.find(
      (country) => country.name.code === formState.country
    );

    const selectedRegion = regions.find(
      (region) => region.name.stateCode === formState.region
    );

    emailParams.country = selectedCountry.name.label;
    emailParams.region = selectedRegion.name.stateName;

    try {
      const result = await sendEmailApi.post('/', emailParams);
      if (result.data.status === 200) {
        setIsEmailSending(false);
        setModalTitle('Success');
        setModalMessage(result.data.message);
        setValidated(false);
        setFormState(INITIAL_STATE);
        setErrors(INITIAL_STATE);
        setRegions([]);
      }
    } catch (error) {
      setModalMessage('Email not sent!');
      setIsEmailSending(false);
      setModalTitle('Error');
      console.error(error);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormState({ ...formState, [name]: value });

    if (name === 'country') {
      retrieveCountryRegionApi
        .get(`/states/${value}/en.json`)
        .then((response) => {
          const data = response.data;
          setRegions(
            Object.keys(data).map((key) => ({ code: key, name: data[key] }))
          );
        })
        .catch((error) => console.log(error));
    }
  };

  return (
    <>
      <Modal
        show={showModal}
        onHide={handleClose}
        animation={false}
        aria-labelledby='contained-modal-title-vcenter'
        centered
        className='contact'
      >
        <Modal.Header closeButton>
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='modal-email-content'>
            {isEmailSending && <Spinner animation='border' variant='light' />}
            <div>{modalMessage}</div>
          </div>
        </Modal.Body>
        <Modal.Footer>&nbsp;</Modal.Footer>
      </Modal>
      <section id='contact-us'>
        <div className='contact-us-container'>
          <div className='contact-us-text-container'>
            <h1 className='contact-us-title'>We're here for you!</h1>
            <p className='contact-us-subtitle'>
              Let us know your inquiries and get your project started!
            </p>
          </div>
          <div className='contact-us-form-container'>
            <div className='contact-form'>
              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Container className='p-0 m-0' fluid>
                  <Row className='px-0 py-lg-3 m-0 d-flex'>
                    <Col xs={12} lg={6} className='p-0 mx-0 mb-3 mb-lg-0'>
                      <Form.Group
                        className='me-lg-3 position-relative'
                        controlId='formFirstName'
                      >
                        <Form.Label>FIRST NAME</Form.Label>
                        <Form.Control
                          type='text'
                          name='firstName'
                          required
                          value={formState.firstName}
                          onChange={handleInputChange}
                        />
                        <Form.Control.Feedback type='invalid' tooltip>
                          {errors.firstName}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col xs={12} lg={6} className='p-0 mx-0 mb-3 mb-lg-0 '>
                      <Form.Group
                        className='position-relative'
                        controlId='formLastName'
                      >
                        <Form.Label>LAST NAME</Form.Label>
                        <Form.Control
                          type='text'
                          name='lastName'
                          required
                          value={formState.lastName}
                          onChange={handleInputChange}
                        />
                        <Form.Control.Feedback type='invalid' tooltip>
                          {errors.lastName}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className='px-0 py-lg-3 m-0 d-flex'>
                    <Col xs={12} lg={6} className='p-0 mx-0 mb-3 mb-lg-0 '>
                      <Form.Group
                        className='me-lg-3 position-relative'
                        controlId='formCountry'
                      >
                        <Form.Label>COUNTRY</Form.Label>
                        <Form.Select
                          name='country'
                          required
                          value={formState.country}
                          onChange={handleInputChange}
                        >
                          {countries.map((country) => {
                            return (
                              <option
                                key={country.code}
                                value={country.name.code}
                              >
                                {country.name.label}
                              </option>
                            );
                          })}
                        </Form.Select>
                        <Form.Control.Feedback type='invalid' tooltip>
                          {errors.country}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col xs={12} lg={6} className='p-0 mx-0 mb-3 mb-lg-0 '>
                      <Form.Group
                        className='position-relative'
                        controlId='formRegion'
                      >
                        <Form.Label>REGION</Form.Label>
                        <Form.Select
                          name='region'
                          required
                          value={formState.region}
                          onChange={handleInputChange}
                        >
                          <option value=''>Select Region</option>
                          {regions.map((region) => {
                            return (
                              <option
                                key={region.code}
                                value={region.name.stateCode}
                              >
                                {region.name.stateName}
                              </option>
                            );
                          })}
                        </Form.Select>
                        <Form.Control.Feedback type='invalid' tooltip>
                          {errors.region}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className='px-0 py-lg-3 m-0 d-flex'>
                    <Col xs={12} lg={6} className='p-0 mx-0 mb-3 mb-lg-0 '>
                      <Form.Group
                        className='me-lg-3 position-relative'
                        controlId='formPhone'
                      >
                        <Form.Label>PHONE</Form.Label>
                        <Form.Control
                          type='tel'
                          name='phone'
                          required
                          value={formState.phone}
                          onChange={handleInputChange}
                        />
                        <Form.Control.Feedback type='invalid' tooltip>
                          {errors.phone}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col xs={12} lg={6} className='p-0 mx-0 mb-3 mb-lg-0 '>
                      <Form.Group
                        className='position-relative'
                        controlId='formEmail'
                      >
                        <Form.Label>EMAIL</Form.Label>
                        <Form.Control
                          type='email'
                          required
                          name='email'
                          value={formState.email}
                          onChange={handleInputChange}
                        />
                        <Form.Control.Feedback type='invalid' tooltip>
                          {errors.email}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className='px-0 py-lg-3 m-0'>
                    <Col className='p-0 mx-0 mb-3 mb-lg-0 '>
                      <Form.Group
                        className='position-relative'
                        controlId='formCompany'
                      >
                        <Form.Label>COMPANY OR ORGANIZATION</Form.Label>
                        <Form.Control
                          type='text'
                          required
                          name='company'
                          value={formState.company}
                          onChange={handleInputChange}
                        />
                        <Form.Control.Feedback type='invalid' tooltip>
                          {errors.company}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className='px-0 py-lg-3 m-0'>
                    <Col className='p-0 m-0 '>
                      <Form.Group
                        className='position-relative'
                        controlId='formMessage'
                      >
                        <Form.Label>MESSAGE</Form.Label>
                        <Form.Control
                          as='textarea'
                          rows={6}
                          required
                          name='message'
                          value={formState.message}
                          onChange={handleInputChange}
                        />
                        <Form.Control.Feedback type='invalid' tooltip>
                          {errors.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className='p-0 m-0'>
                    <Col className='p-0 m-0'>
                      <div className='policy-text'>
                        By submitting this form, I acknowledge that I have read
                        and understood the{' '}
                        <span className='light-blue'>
                          <a
                            href='https://sparkpoint.io/#/privacy'
                            target='_blank'
                            rel='noreferrer'
                          >
                            SparkTech.Dev Privacy Policy
                          </a>
                        </span>
                      </div>
                    </Col>
                  </Row>
                  <Row className='px-0 pt-5 m-0'>
                    <Col className='p-0 m-0 d-flex justify-content-end'>
                      <div id='custom-button'>
                        <Button type='submit'>SUBMIT</Button>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </Form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactUs;
