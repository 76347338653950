import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import CustomButton from '../CustomButton';
import navbarImg from '../../assets/images/navigation/navbar-img.png';
import '../../styles/components/navigation.css';

const MobileNav = () => {
  return (
    <Container fluid className='mobile-nav m-0 p-0'>
      <Navbar expand='lg' bg='dark' variant='dark' collapseOnSelect>
        <Container fluid>
          <Navbar.Brand href='#home'>
            <div className='nav-header'>
              <img className='header-img' src={navbarImg} alt='SparkTech.Dev' />
            </div>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls='basic-navbar-nav' />
          <Navbar.Collapse id='basic-navbar-nav'>
            <div className='navigation'>
              <Nav className='me-auto'>
                <Nav.Item>
                  <Nav.Link href='/#home'>HOME</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link href='/#services'>SERVICES</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link href='/#portfolio'>PORTFOLIO</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link href='/#about'>ABOUT US</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link href='/#contact-us'>CONTACT US</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <CustomButton url='/#contact-us' paddingSize='sm'>
                    INQUIRE NOW
                  </CustomButton>
                </Nav.Item>
              </Nav>
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </Container>
  );
};

export default MobileNav;
