import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import HeroCarousel from '../../components/Carousel/HeroCarousel';
import Navigation from '../../components/Navigation';
import MobileNav from '../../components/Navigation/MobileNav';
import '../../styles/sections/home.css';
import '../../styles/base/common.css';

const Home = () => {
  return (
    <section id='home'>
      <div className='header-container'>
        <div className='d-none d-lg-block'>
          <Navigation />
        </div>
        <div className='d-block d-lg-none'>
          <MobileNav />
        </div>
        <div className='hero-container'>
          <div className='hero-carousel'>
            <HeroCarousel />
          </div>
        </div>
      </div>
      <div id='about' className='intro-area'>
        <div className='text-container'>
          <p className='intro-text'>
            Onboarding businesses to Web3 through building innovative products
            and custom software development solutions and services.
          </p>
          <p className='intro-sub'>
            We believe that Blockchain Technology is the catalyst for the new
            era of tech-innovation, and SparkTech Dev is here for you to be
            future-ready.
          </p>
        </div>
      </div>
      <div className='process'>
        <div className='process-blocks'>
          <div className='block-container'>
            <div className='process-icon-container'>
              <div className='process-ideate-icon'></div>
            </div>
            <div className='process-text-container'>
              <div className='process-header'>IDEATE</div>
              <div className='process-title'>From ZERO to SPARKS</div>
              <div className='process-description'>
                From concepts into reality, we work closely with teams and
                businesses to create tailored solutions that deliver the results
                they need for their “next big project”.
              </div>
            </div>
          </div>
          <div className='block-container block-container--reverse'>
            <div className='process-icon-container'>
              <div className='process-build-icon'></div>
            </div>
            <div className='process-text-container'>
              <div className='process-header'>BUILD</div>
              <div className='process-title'>Coding your way to success</div>
              <div className='process-description'>
                Sweat-free, our experienced software developers and engineers
                are always ready to bring your vision to life. We take care of
                the coding so you could focus more on growing your business.
              </div>
            </div>
          </div>
          <div className='block-container'>
            <div className='process-icon-container'>
              <div className='process-execute-icon'></div>
            </div>
            <div className='process-text-container'>
              <div className='process-header'>EXECUTE</div>
              <div className='process-title'>Deploy with confidence</div>
              <div className='process-description'>
                Over the years, we have always prioritized quality over
                quantity. Our team makes sure that everything works, from
                backend to frontend, it should be “all systems go.”
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='stats-container'>
        <div className='stats'>
          <Container fluid className='m-0 p-0'>
            <Row className='mb-4 mb-md-5 mb-lg-5'>
              <Col xs={12}>
                <div className='stats-content stats-box stats-bg'>
                  <span className='stats-num'>500,000+</span>
                  <span className='stats-description'>
                    combined individuals served worldwide through various
                    projects that were launched since 2018
                  </span>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12} lg={6} className='mb-4 mb-md-5 mb-lg-0'>
                <div className='stats-content stats-box stats-bg'>
                  <span className='stats-num'>20+</span>
                  <span className='stats-description'>
                    Web2 + Web3 projects launched since 2018
                  </span>
                </div>
              </Col>
              <Col xs={12} lg={6} className='mb-4 mb-md-5 mb-lg-0'>
                <div className='stats-content stats-box stats-bg'>
                  <span className='stats-num'>50+</span>
                  <span className='stats-description'>
                    specialists in software dev., marketing, and operations
                  </span>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </section>
  );
};

export default Home;
