import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Carousel from 'react-bootstrap/Carousel';
import svgLogo from '../../../assets/images/partners/sgv-logo.png';
import sparklearnLogo from '../../../assets/images/partners/sparklearn-logo.png';
import sparkpointLogo from '../../../assets/images/partners/sparkpoint-logo.png';
import sparkswapLogo from '../../../assets/images/partners/sparkswap-logo.png';
import mggLogo from '../../../assets/images/partners/mgg-logo.png';
import ownlyLogo from '../../../assets/images/partners/ownly-logo.png';
import anitoLegendsLogo from '../../../assets/images/partners/anito-legends-logo.png';
import gdapLogo from '../../../assets/images/partners/gdap-logo.png';
import web3phLogo from '../../../assets/images/partners/web3ph-logo.png';
import popsicleGamesLogo from '../../../assets/images/partners/popsicle-games-logo.png';
import '../../../styles/components/partners-carousel.css';

const PartnersCarousel = () => {
  return (
    <>
      <div className='d-none d-lg-block full-width'>
        <Carousel indicators={false} interval={null}>
          <Carousel.Item>
            <div className='partners-carousel-container'>
              <div className='partners-list-container'>
                <Container fluid className='m-0 p-0'>
                  <Row className=' m-0 mb-lg-5 p-0 py-lg-5'>
                    <Col className='m-0 p-0 partner d-flex justify-content-center align-items-center'>
                      <a
                        href='https://sparkpoint.global'
                        target='_blank'
                        rel='noreferrer'
                      >
                        <img src={svgLogo} alt='SparkPoint Global Ventures' />
                      </a>
                    </Col>
                    <Col className='m-0 p-0 partner d-flex justify-content-center align-items-center'>
                      <a
                        href='https://sparkpoint.io'
                        target='_blank'
                        rel='noreferrer'
                      >
                        <img src={sparkpointLogo} alt='SparkPoint ' />
                      </a>
                    </Col>
                    <Col className='m-0 p-0 partner d-flex justify-content-center align-items-center'>
                      <a
                        href='https://sparklearn-edtech.com'
                        target='_blank'
                        rel='noreferrer'
                      >
                        <img src={sparklearnLogo} alt='SparkLearn EdTech ' />
                      </a>
                    </Col>
                  </Row>
                  <Row className='m-0 mt-lg-5 p-0 py-lg-5'>
                    <Col className='m-0 p-0 partner d-flex justify-content-center align-items-center'>
                      <a
                        href='https://sparkswap.finance'
                        target='_blank'
                        rel='noreferrer'
                      >
                        <img src={sparkswapLogo} alt='SparkSwap' />
                      </a>
                    </Col>
                    <Col className='m-0 p-0 partner d-flex justify-content-center align-items-center'>
                      <a
                        href='https://www.metagg.com'
                        target='_blank'
                        rel='noreferrer'
                      >
                        <img src={mggLogo} alt='MetaGaming Guild' />
                      </a>
                    </Col>
                    <Col className='m-0 p-0 partner d-flex justify-content-center align-items-center'>
                      <a
                        href='https://ownly.io'
                        target='_blank'
                        rel='noreferrer'
                      >
                        <img src={ownlyLogo} alt='Ownly' />
                      </a>
                    </Col>
                  </Row>
                </Container>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className='partners-carousel-container'>
              <div className='partners-list-container'>
                <Row className='m-0 mb-lg-3 p-0'>
                  <Col className='m-0 p-0 partner d-flex justify-content-center align-items-center'>
                    <a
                      href='https://anitolegends.com'
                      target='_blank'
                      rel='noreferrer'
                    >
                      <img
                        src={anitoLegendsLogo}
                        className='partner-logo-small'
                        alt='Anito Legends '
                      />
                    </a>
                  </Col>
                  <Col className='m-0 p-0 partner d-flex justify-content-center align-items-center'>
                    <img
                      src={popsicleGamesLogo}
                      className='partner-logo-small'
                      alt='Popsicle Games'
                    />
                  </Col>
                </Row>
                <Row className=' m-0 mt-lg-3 p-0'>
                  <Col className='m-0 p-0 partner d-flex justify-content-center align-items-center'>
                    <a
                      href='https://web3philippines.org'
                      target='_blank'
                      rel='noreferrer'
                    >
                      <img
                        src={web3phLogo}
                        className='partner-logo-small'
                        alt='Web3PH '
                      />
                    </a>
                  </Col>
                  <Col className='m-0 p-0 partner d-flex justify-content-center align-items-center'>
                    <a
                      href='https://www.gdap.org.ph'
                      target='_blank'
                      rel='noreferrer'
                    >
                      <img
                        src={gdapLogo}
                        className='partner-logo-small'
                        alt='GDAP '
                      />
                    </a>
                  </Col>
                </Row>
              </div>
            </div>
          </Carousel.Item>
        </Carousel>
      </div>
      <div className='d-block d-lg-none full-width'>
        <Carousel indicators={false} interval={null}>
          <Carousel.Item>
            <div className='partners-carousel-container'>
              <div className='partners-carousel-container'>
                <div className='partners-list-container'>
                  <Container
                    fluid
                    className='m-0 p-0 d-flex justify-content-around flex-column container-partners'
                  >
                    <Row className='m-0 p-0'>
                      <Col
                        xs={12}
                        className='m-0 p-0 partner d-flex justify-content-center align-items-center'
                      >
                        <a
                          href='https://sparkpoint.global'
                          target='_blank'
                          rel='noreferrer'
                        >
                          <img src={svgLogo} alt='SparkPoint Global Ventures' />
                        </a>
                      </Col>
                    </Row>
                    <Row className=' m-0 p-0'>
                      <Col
                        xs={12}
                        className='m-0 p-0 partner d-flex justify-content-center align-items-center'
                      >
                        <a
                          href='https://sparkpoint.io'
                          target='_blank'
                          rel='noreferrer'
                        >
                          <img src={sparkpointLogo} alt='SparkPoint ' />
                        </a>
                      </Col>
                    </Row>
                  </Container>
                </div>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className='partners-carousel-container'>
              <div className='partners-carousel-container'>
                <div className='partners-list-container'>
                  <Container
                    fluid
                    className='m-0 p-0  d-flex justify-content-around flex-column container-partners'
                  >
                    <Row className=' m-0 p-0'>
                      <Col
                        xs={12}
                        className='m-0 p-0 partner d-flex justify-content-center align-items-center'
                      >
                        <a
                          href='https://sparklearn-edtech.com'
                          target='_blank'
                          rel='noreferrer'
                        >
                          <img src={sparklearnLogo} alt='SparkLearn EdTech ' />
                        </a>
                      </Col>
                    </Row>
                    <Row className=' m-0 p-0'>
                      <Col
                        xs={12}
                        className='m-0 p-0 partner d-flex justify-content-center align-items-center'
                      >
                        <a
                          href='https://sparkswap.finance'
                          target='_blank'
                          rel='noreferrer'
                        >
                          <img src={sparkswapLogo} alt='SparkSwap' />
                        </a>
                      </Col>
                    </Row>
                  </Container>
                </div>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className='partners-carousel-container'>
              <div className='partners-carousel-container'>
                <div className='partners-list-container'>
                  <Container
                    fluid
                    className='m-0 p-0  d-flex justify-content-around flex-column container-partners'
                  >
                    <Row className='m-0 p-0'>
                      <Col
                        xs={12}
                        className='m-0 p-0 partner d-flex justify-content-center align-items-center'
                      >
                        <a
                          href='https://www.metagg.com'
                          target='_blank'
                          rel='noreferrer'
                        >
                          <img src={mggLogo} alt='MetaGaming Guild' />
                        </a>
                      </Col>
                    </Row>
                    <Row className='m-0 p-0'>
                      <Col
                        xs={12}
                        className='m-0 p-0 partner d-flex justify-content-center align-items-center'
                      >
                        <a
                          href='https://ownly.io'
                          target='_blank'
                          rel='noreferrer'
                        >
                          <img src={ownlyLogo} alt='Ownly' />
                        </a>
                      </Col>
                    </Row>
                  </Container>
                </div>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className='partners-carousel-container'>
              <div className='partners-list-container'>
                <Container
                  fluid
                  className='m-0 p-0  d-flex justify-content-around flex-column container-partners'
                >
                  <Row className='m-0 p-0'>
                    <Col
                      xs={12}
                      className='m-0 p-0 partner d-flex justify-content-center align-items-center'
                    >
                      <a
                        href='https://anitolegends.com'
                        target='_blank'
                        rel='noreferrer'
                      >
                        <img
                          src={anitoLegendsLogo}
                          alt='Anito Legends '
                          className='partner-logo-small'
                        />
                      </a>
                    </Col>
                  </Row>
                  <Row className=' m-0 p-0'>
                    <Col
                      xs={12}
                      className='m-0 p-0 partner d-flex justify-content-center align-items-center'
                    >
                      <a
                        href='https://www.gdap.org.ph'
                        target='_blank'
                        rel='noreferrer'
                      >
                        <img
                          src={gdapLogo}
                          alt='GDAP'
                          className='partner-logo-small'
                        />
                      </a>
                    </Col>
                  </Row>
                </Container>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className='partners-carousel-container'>
              <div className='partners-carousel-container'>
                <div className='partners-list-container'>
                  <Container
                    fluid
                    className='m-0 p-0  d-flex justify-content-around flex-column container-partners'
                  >
                    <Row className=' m-0 p-0'>
                      <Col
                        xs={12}
                        className='m-0 p-0 partner d-flex justify-content-center align-items-center'
                      >
                        <img
                          src={popsicleGamesLogo}
                          alt='Popsicle Games'
                          className='partner-logo-small'
                        />
                      </Col>
                    </Row>
                    <Row className=' m-0 p-0'>
                      <Col
                        xs={12}
                        className='m-0 p-0 partner d-flex justify-content-center align-items-center'
                      >
                        <a
                          href='https://web3philippines.org'
                          target='_blank'
                          rel='noreferrer'
                        >
                          <img
                            src={web3phLogo}
                            alt='Web3PH'
                            className='partner-logo-small'
                          />
                        </a>
                      </Col>
                    </Row>
                  </Container>
                </div>
              </div>
            </div>
          </Carousel.Item>
        </Carousel>
      </div>
    </>
  );
};

export default PartnersCarousel;
